@use '@angular/material' as mat;

@include mat.core();

$mat-primary: mat.define-palette(mat.$grey-palette, 500);
$mat-accent: mat.define-palette(mat.$grey-palette);
$mat-theme: mat.define-light-theme(
    (
        color: (
            primary: $mat-primary,
            accent: $mat-accent,
        ),
        typography: mat.define-typography-config(),
        density: -2,
    )
);

// You could include all with mat.all-components-theme()
// But only turning on what you need should be a bit lighter.
@include mat.core-theme($mat-theme);

//@include mat.autocomplete-theme($mat-theme);
//@include mat.badge-theme($mat-theme);
//@include mat.bottom-sheet-theme($mat-theme);
//@include mat.button-theme($mat-theme);
//@include mat.button-toggle-theme($mat-theme);
//@include mat.card-theme($mat-theme);
//@include mat.checkbox-theme($mat-theme);
//@include mat.chips-theme($mat-theme);
//@include mat.datepicker-theme($mat-theme);
//@include mat.dialog-theme($mat-theme);
//@include mat.divider-theme($mat-theme);
//@include mat.expansion-theme($mat-theme);
//@include mat.fab-theme($mat-theme);
@include mat.form-field-theme($mat-theme);
//@include mat.grid-list-theme($mat-theme);
//@include mat.icon-button-theme($mat-theme);
//@include mat.icon-theme($mat-theme);
//@include mat.input-theme($mat-theme);
//@include mat.list-theme($mat-theme);
//@include mat.menu-theme($mat-theme);
@include mat.paginator-theme($mat-theme);
//@include mat.progress-bar-theme($mat-theme);
//@include mat.progress-spinner-theme($mat-theme);
//@include mat.radio-theme($mat-theme);
@include mat.select-theme($mat-theme);
//@include mat.sidenav-theme($mat-theme);
//@include mat.slide-toggle-theme($mat-theme);
//@include mat.slider-theme($mat-theme);
//@include mat.snack-bar-theme($mat-theme);
//@include mat.sort-theme($mat-theme);
//@include mat.stepper-theme($mat-theme);
@include mat.table-theme($mat-theme);
//@include mat.tabs-theme($mat-theme);
//@include mat.toolbar-theme($mat-theme);
@include mat.tooltip-theme($mat-theme);
//@include mat.tree-theme($mat-theme);

/*******************
 * Typography      *
 *******************/

// Todo: this does not yet account for the searchie/help pop-up.
html {
    $header-font-family: Montserrat, sans-serif;
    $default-font-family: 'Open Sans', sans-serif;

    --ion-font-family: #{$default-font-family};

    font-size: 15px;

    ion-button,
    ion-label,
    ion-header,
    ion-segment {
        --ion-font-family: #{$header-font-family};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .page-title,
    .header-font-family,
    .cdk-header-row {
        font-family: $header-font-family;
    }

    .default-font-family,
    .cdk-row,
    .cdk-footer-row {
        font-family: $default-font-family;
    }

    p {
        text-wrap: pretty;
    }
}

/*******************
 * Structure       *
 *******************/
section {
    --max-width: 1560px;

    margin-inline: auto;
    max-width: var(--max-width);
}

.form-width {
    --max-width: 1100px;
}

.reading-width {
    --max-width: 880px;
}

.narrow-width {
    --max-width: 72ch;
}

// This should only be used on the registration wrapper pages.
.vertically-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;

    @media (max-width: 1100px) {
        justify-content: start;
    }
}

.text-center {
    text-align: center;
}

// Dynamic modal height strategy from
// https://forum.ionicframework.com/t/how-can-i-set-modals-height-to-be-equal-to-its-content/214941/6
.video-modal-height {
    --height: auto;
    --width: 90%;
    --max-width: 1200px;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
        }
    }
}

/*******************
 * Utils           *
 *******************/
.cursor-pointer {
    cursor: pointer;
}

.search-container {
    align-items: center;
    background-color: var(--background);
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    // Todo: Not sure stickiness should be decided here. Probably belongs in component scss.
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;

    // Todo: It feels like this should be separate from the search-container class.
    ion-searchbar {
        --background: rgba(var(--ion-color-primary-rgb, 0, 0, 0), 0.2);
        --border-radius: 4px;
    }
}

hr {
    border: none;
    border-top: 2px solid var(--ion-color-tertiary);
}

hr.grey-hr {
    border-top-color: #f1f1f1;
}

.form-control-margin {
    display: block;
    margin-block-end: 1.25rem;
}

.large-readable-modal {
    --width: 90%;
    --max-width: 92ch;
}

.filter-modal,
.skill-analysis-modal {
    --width: 90%;
    --max-width: 1200px;
}

.centered-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.elevation-1 {
    padding: 1rem;
    background: white;
    box-shadow:
        rgba(0, 0, 0, 0.2) 0 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.no-padding,
elevation-1.no-padding {
    padding: 0;
}

.page-title {
    color: var(--ion-color-medium);
    font-size: 2em;
    margin-block: 1rem;
    text-align: center;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-right-auto {
    margin-right: auto;
}

/*******************
 * Overrides       *
 *******************/
lib-phone-number-control {
    // We've chosen to hide the flag picker button globally for now.
    // The default country code is US and is unlikely to change for a long time,
    // so it only adds visual noise to display the flag.
    $show-flag-button: false;

    // I chose to write an if-else here
    // in order to preserve the way we resolved the misaligned error text
    // should we ever turn flag selection back on.
    @if $show-flag-button {
        + lib-control-error {
            // Todo: This is not ideal way to solve the error alignment issues with lib-phone-number-control.
            //  However, at the moment, it feels best to keep the display of errors outside said component.
            padding-left: 43.7px;
        }
    } @else {
        button {
            display: none;
        }
    }
}

ion-button.mimic-ion-select {
    // Todo: This is my attempt at styling an ion-button to look similar to an ion-select,
    //  but I'm not sure if this is a good approach or not.
    //  In the future, restyling a regular button might offer more control.
    --background-focused: unset;
    --border-color: var(--ion-color-step-300, #b3b3b3);
    --border-width: 1px;
    --color: black;

    font-size: 1rem;
    height: 56px;
    letter-spacing: unset;
    margin: 0;
    text-transform: unset;

    &:hover,
    &:focus {
        --border-color: black;
    }

    ion-icon {
        color: var(--ion-color-step-500, gray);
        height: 0.75rem;
    }
}

ion-card {
    background-color: white;
}

video {
    max-width: 100%;
    width: 100%;
    transition: transform 0.5s;
}

// Restyle the Select Input's alert buttons text to a readable color
ion-alert.select-alert button.alert-button {
    color: var(--ion-color-dark);

    &.alert-button-role-cancel {
        color: var(--ion-color-medium);
    }
}

/*
    Ensure checkboxes with labelPlacement="end"
    can be tabbed to and focused on.
    This is relevant on certain components
    using that label placement
*/
ion-checkbox[labelPlacement='end'],
ion-checkbox[label-placement='end'] {
    &:focus::part(label)::after {
        aspect-ratio: 1;
        // Copied and modified from ion-radio styling
        background: var(--ion-color-tint, var(--ion-color-primary-tint, lightgray));
        border-radius: 3px;
        content: '';
        display: block;
        left: -2px;
        opacity: 0.2;
        position: absolute;
        top: 0;
        width: 22px;
        z-index: 1;
    }
}

.block-heading {
    background-color: rgba(var(--ion-color-tertiary-rgb), 0.5);
    padding: 10px 15px;
}

.list-style-none {
    list-style-type: none;
    margin-block-start: unset;
    padding-inline-start: 0;
}

// Icon color theming for quick global changes
// Back button arrow icon
ion-icon[name='arrow-back'],
ion-icon[name='arrow-forward'] {
    color: var(--ion-color-medium);
}

// Delete button trash icon
ion-icon[name='trash-outline'],
ion-icon[name='trash'] {
    color: var(--ion-color-medium);
}

// Edit button edit icon
ion-icon[src='assets/icons/edit.svg'] {
    color: var(--ion-color-medium);
}

ion-toolbar {
    --background: #90e5e8;
}

a {
    background-color: transparent;
    color: var(--ion-color-links);
    text-decoration: none;
}

// This is designed to work with ion-item element.
// i.e. ion-item > div[slot='<start|end>'].menu-item-icon > img|ion-icon.
$icon-background-gradient: linear-gradient(to bottom, #f683d6, #f9a959);

.menu-item-icon {
    --background-size: 36px;
    --border-radius: 50%;
    --icon-color: white;
    --icon-size: 22.5px;

    // Maintains slot size to ion-icons in the same list.
    --slot-margin: calc(32px + var(--icon-size) - var(--background-size));

    align-items: center;
    background: $icon-background-gradient;
    border-radius: var(--border-radius);
    display: flex;
    height: var(--background-size);
    justify-content: center;
    width: var(--background-size);

    &[slot='start'] {
        margin-inline-end: var(--slot-margin);
    }

    &[slot='end'] {
        margin-inline-start: var(--slot-margin);
    }

    img {
        color: var(--icon-color);
        height: var(--icon-size);
        width: var(--icon-size);
    }

    ion-icon {
        color: var(--icon-color);
        height: var(--icon-size);
        width: var(--icon-size);
    }

    &.transparent {
        background: transparent;
    }
}

.media-element {
    max-width: 100%;
    width: 500px;
    display: block;
    margin-block: 1rem;
}

h3 {
    font-size: 1.2em;
}

// Logo icon on the account pages
img.logo {
    width: 12rem;
}

// This sets the accordion icon to only turn 90 degrees when expanded
.accordion-icon-quarter-turn > ion-accordion {
    > [slot='header'] .ion-accordion-toggle-icon {
        color: black;
    }

    &.accordion-expanded,
    &.accordion-expanding {
        > [slot='header'] .ion-accordion-toggle-icon {
            transform: rotate(90deg);
        }
    }
}

.program-scaffold-blue {
    background-color: var(--program-scaffold-blue);
}

.program-grade-level-d {
    background-color: var(--program-grade-level-d);
}

.program-grade-level-e {
    background-color: var(--program-grade-level-e);
}

.program-grade-level-f {
    background-color: var(--program-grade-level-f);
}

.program-grade-level-g {
    background-color: var(--program-grade-level-g);
}

.play-icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
    color: white;
    border-radius: 100%;
    background: darkgrey;
    opacity: 0.8;
}

.playable-image-container {
    display: inline-block;
    position: relative;
    text-align: center;
    height: max-content;
}

.tooltip {
    position: relative;
    z-index: 100;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    font-size: 0.8rem;
}

.link-color ion-icon {
    color: var(--ion-color-links);
}

.error-text {
    color: var(--ion-color-danger);
    font-size: 0.75em;
    position: absolute;
    bottom: 0;
}

ion-accordion.skill-accordion {
    background: aliceblue;

    ion-item {
        font-size: 1.1rem;
    }

    ion-col {
        p {
            margin-top: 0;
        }
    }

    .skills-item {
        position: relative;
        padding-inline-start: 1rem;

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            right: 0;
            bottom: 1px;
            width: 10px;
            background: var(--item-background-color);
            z-index: 10;
        }
    }

    .short-code {
        width: 85px;
        padding-left: 0.5rem;
    }
}
