// This file provides Ionic Variables and Theming
// shared between EBA's different frontend applications.
// These values can be overridden
// by the variables.scss folder in each application's theme folder.

// For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables (light theme) **/
:root {
    --ion-color-primary: #906bcf;
    --ion-color-primary-rgb: 144, 107, 207;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #7f5eb6;
    --ion-color-primary-tint: #9b7ad4;

    --ion-color-secondary: #ff9de4;
    --ion-color-secondary-rgb: 255, 157, 228;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #e08ac9;
    --ion-color-secondary-tint: #ffa7e7;

    --ion-color-tertiary: #ffc280;
    --ion-color-tertiary-rgb: 255, 194, 128;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #e0ab71;
    --ion-color-tertiary-tint: #ffc88d;

    --ion-color-success: #40e261;
    --ion-color-success-rgb: 64, 226, 97;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #38c755;
    --ion-color-success-tint: #53e571;

    --ion-color-warning: #fbda6b;
    --ion-color-warning-rgb: 251, 218, 107;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #ddc05e;
    --ion-color-warning-tint: #fbde7a;

    --ion-color-danger: #f25a67;
    --ion-color-danger-rgb: 242, 90, 103;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #d54f5b;
    --ion-color-danger-tint: #f36b76;

    --ion-color-light: #f0f4ff;
    --ion-color-light-rgb: 240, 244, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d3d7e0;
    --ion-color-light-tint: #f2f5ff;

    --ion-color-medium: #7b839c;
    --ion-color-medium-rgb: 168, 176, 199;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #949baf;
    --ion-color-medium-tint: #b1b8cd;

    --ion-color-dark: #363c54;
    --ion-color-dark-rgb: 54, 60, 84;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #30354a;
    --ion-color-dark-tint: #4a5065;

    --ion-color-links: #04afd1;
    --ion-color-links-rgb: 4, 175, 209;
    --ion-color-links-contrast: #000000;
    --ion-color-links-contrast-rgb: 0, 0, 0;
    --ion-color-links-shade: #049ab8;
    --ion-color-links-tint: #1db7d6;

    // Explicitly set background for consistency
    // and for reference in components
    --ion-background-color: #f9fbfd;
    --ion-background-color-rgb: 249, 251, 253;

    --ion-text-color: #0a0a0a;
    --ion-text-color-rgb: 10, 10, 10;

    // Dashboard UI variables

    --left-background: white;
    --right-background: #f1f1f1;

    --custom-yellow: #fde79c;
    --custom-orange: #fdc78c;
    --custom-blue: #91e5e8;
    --custom-pink: #fdb8e9;
    --custom-purple: #cd7fd5;

    --program-scaffold-blue: #5be1e6;
    --program-grade-level-d: #fbda6a;
    --program-grade-level-e: #cd7fd5;
    --program-grade-level-f: #ffbd59;
    --program-grade-level-g: #ff82dc;

    --text-color: var(--ion-text-color);
    --text-color-light: white;
    --highlight-color: #ffefafa0;
    --highlight-hover-color: rgba(255, 239, 175, 0.33);

    // Todo: remove these once using Ionic 8
    // Ionic 7 generated: https://ionicframework.com/docs/v7/theming/themes#application-colors
    //--ion-color-step-50: #edeff1;
    //--ion-color-step-100: #e1e3e5;
    //--ion-color-step-150: #d5d7d9;
    //--ion-color-step-200: #c9cbcc;
    //--ion-color-step-250: #bdbfc0;
    //--ion-color-step-300: #b1b3b4;
    //--ion-color-step-350: #a5a7a8;
    //--ion-color-step-400: #999b9c;
    //--ion-color-step-450: #8d8f90;
    //--ion-color-step-500: #828384;
    //--ion-color-step-550: #767677;
    //--ion-color-step-600: #6a6a6b;
    //--ion-color-step-650: #5e5e5f;
    //--ion-color-step-700: #525253;
    //--ion-color-step-750: #464647;
    //--ion-color-step-800: #3a3a3b;
    //--ion-color-step-850: #2e2e2e;
    //--ion-color-step-900: #222222;
    //--ion-color-step-950: #161616;

    // TODO: do we need these once we upgrade to Ionic 8?
    // Ionic 8 generated: https://ionicframework.com/docs/theming/themes#application-colors
    //--ion-text-color-step-50: #161616;
    //--ion-text-color-step-100: #222222;
    //--ion-text-color-step-150: #2e2e2e;
    //--ion-text-color-step-200: #3a3a3b;
    //--ion-text-color-step-250: #464647;
    //--ion-text-color-step-300: #525253;
    //--ion-text-color-step-350: #5e5e5f;
    //--ion-text-color-step-400: #6a6a6b;
    //--ion-text-color-step-450: #767677;
    //--ion-text-color-step-500: #828384;
    //--ion-text-color-step-550: #8d8f90;
    //--ion-text-color-step-600: #999b9c;
    //--ion-text-color-step-650: #a5a7a8;
    //--ion-text-color-step-700: #b1b3b4;
    //--ion-text-color-step-750: #bdbfc0;
    //--ion-text-color-step-800: #c9cbcc;
    //--ion-text-color-step-850: #d5d7d9;
    //--ion-text-color-step-900: #e1e3e5;
    //--ion-text-color-step-950: #edeff1;
    //
    //--ion-background-color-step-50: #edeff1;
    //--ion-background-color-step-100: #e1e3e5;
    //--ion-background-color-step-150: #d5d7d9;
    //--ion-background-color-step-200: #c9cbcc;
    //--ion-background-color-step-250: #bdbfc0;
    //--ion-background-color-step-300: #b1b3b4;
    //--ion-background-color-step-350: #a5a7a8;
    //--ion-background-color-step-400: #999b9c;
    //--ion-background-color-step-450: #8d8f90;
    //--ion-background-color-step-500: #828384;
    //--ion-background-color-step-550: #767677;
    //--ion-background-color-step-600: #6a6a6b;
    //--ion-background-color-step-650: #5e5e5f;
    //--ion-background-color-step-700: #525253;
    //--ion-background-color-step-750: #464647;
    //--ion-background-color-step-800: #3a3a3b;
    //--ion-background-color-step-850: #2e2e2e;
    //--ion-background-color-step-900: #222222;
    //--ion-background-color-step-950: #161616;
}

// TODO: Generate and incorporate dark mode
//   using https://ionicframework.com/docs/theming/color-generator
///** Ionic CSS Variables (dark theme) **/
// @media (prefers-color-scheme: dark) {
//     body {
//         --...
//     }
// }
